import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import { useI18n } from 'services/i18n';
import media from 'styles/media';
import { Background, Col, Container, Hidden, Row } from 'styles/grid';
import Layout from 'components/layout';
import SEO from 'components/seo';
import ClientCase from 'components/client-case';
import Block from 'components/block';
import SwitcherBlock from 'components/switcher-block';
import Links from 'components/links';
import Shirt from 'images/icons/shirt.svg';
import Check from 'images/icons/check.svg';
import {
  PrimaryTitle,
  SecondaryTitle,
  BiggerText,
  BigText,
  ParagraphTitle,
  ParagraphText,
} from 'components/texts';
import { DemoLink } from 'components/buttons';

import EN_PluginIntroWEBM from 'videos/en/plugin-intro-en.webm';
import EN_PluginIntroMP4 from 'videos/en/plugin-intro-en.mp4';
import EN_PluginIntroPoster from 'videos/en/plugin-intro-poster-en.png';
import FR_PluginIntroWEBM from 'videos/fr/plugin-intro-fr.webm';
import FR_PluginIntroMP4 from 'videos/fr/plugin-intro-fr.mp4';
import FR_PluginIntroPoster from 'videos/fr/plugin-intro-poster-fr.png';

const VideoSectionContainer = styled(Container)`
  padding: 60px 0;
  video {
    margin-top: 60px;
  }
  ${media.md`
    padding: 100px 0;
    video {
      margin-top: 0px;
    }
  `}
`;

const MainIconRow = styled(Row)`
  margin-bottom: 40px;
  ${media.md`
    margin-bottom: 60px;
  `}
`;

const ListItem = styled(BigText)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  svg {
    /* height: 16px; */
    width: 24px;
    flex: 0 0 24px;
    margin-right: 1em;
  }
`;

const PluginPage = () => {
  const [t, language] = useI18n();
  const data = useStaticQuery(graphql`
    query {
      featureOne: file(relativePath: { eq: "homepage/feature1.png" }) {
        childImageSharp {
          fluid(maxWidth: 476, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      fr: allFile(filter: { relativeDirectory: { eq: "plugin/fr" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
      en: allFile(filter: { relativeDirectory: { eq: "plugin/en" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  `);

  const images = {
    fr: data.fr.nodes.reduce((mapping, image) => {
      mapping[image.name] = image.childImageSharp;
      return mapping;
    }, {}),
    en: data.en.nodes.reduce((mapping, image) => {
      mapping[image.name] = image.childImageSharp;
      return mapping;
    }, {}),
  };

  const video = {
    fr: {
      mp4: FR_PluginIntroMP4,
      webm: FR_PluginIntroWEBM,
      poster: FR_PluginIntroPoster,
    },
    en: {
      mp4: EN_PluginIntroMP4,
      webm: EN_PluginIntroWEBM,
      poster: EN_PluginIntroPoster,
    },
  };

  return (
    <Layout>
      <SEO
        title={t('seo.title.plugin')}
        lang={language}
        description={t('seo.description.plugin')}
      />
      <section>
        <Container>
          <MainIconRow justify="center">
            <Col md="content" style={{ textAlign: 'center' }}>
              <Shirt height="4rem" width="4rem" />
            </Col>
          </MainIconRow>
          <Row justify="center">
            <Col md="content">
              <PrimaryTitle align="center">{t('plugin.title')}</PrimaryTitle>
            </Col>
          </Row>
          <Hidden md lg xl>
            <Row justify="center">
              <Col style={{ marginTop: 40 }} sm="content">
                <DemoLink id="ga-demo-plugin">{t('index.appointment')}</DemoLink>
              </Col>
            </Row>
          </Hidden>
        </Container>
      </section>
      <Background>
        <section>
          <VideoSectionContainer>
            <Row justify="space-around" align="center">
              <Col sm={12} md={5} orderMd={2}>
                <BiggerText weight="bold">{t('plugin.solution')}</BiggerText>
                <ListItem>
                  <Check />
                  {t('plugin.argument1')}
                </ListItem>
                <ListItem>
                  <Check />
                  {t('plugin.argument2')}
                </ListItem>
                <ListItem>
                  <Check />
                  {t('plugin.argument3')}
                </ListItem>
                <ListItem>
                  <Check />
                  {t('plugin.argument4')}
                </ListItem>
                <ListItem>
                  <Check width="16px" />
                  {t('plugin.argument5')}
                </ListItem>
              </Col>
              <Col md={5} orderMd={1}>
                <video autoPlay loop width="100%" poster={video[language].poster} muted playsInline>
                  <source src={video[language].webm} type="video/webm" />
                  <source src={video[language].mp4} type="video/mp4" />
                </video>
              </Col>
            </Row>
          </VideoSectionContainer>
        </section>
      </Background>
      <section>
        <Container>
          <Row>
            <Col>
              <SecondaryTitle>{t('plugin.subtitle')}</SecondaryTitle>
            </Col>
          </Row>
          <SwitcherBlock
            mainTitle={t('plugin.recommendation')}
            titleOne={t('plugin.data')}
            textOne={t('plugin.condition')}
            titleTwo={t('plugin.shape')}
            textTwo={t('plugin.info')}
            imageFluidOne={images[language].info.fluid}
            imageFluidTwo={images[language].bodyshape.fluid}
            altOne={t('seo.alt.plugin.pluginAgeHeightWeight')}
            altTwo={t('seo.alt.plugin.pluginBodyShape')}
          />
          <SwitcherBlock
            mainTitle={t('plugin.preferences')}
            titleOne={t('plugin.fit')}
            textOne={t('plugin.adaptation')}
            titleTwo={t('plugin.references')}
            textTwo={t('plugin.client-clothes')}
            imageFluidOne={images[language].preferences.fluid}
            imageFluidTwo={images[language].references.fluid}
            altOne={t('seo.alt.plugin.pluginFitPreference')}
            altTwo={t('seo.alt.plugin.pluginReferenceBrand')}
            isImageLeft={true}
          />
          <Block
            titleText={t('plugin.reco')}
            imageFluid={images[language].reco.fluid}
            alt={t('seo.alt.plugin.pluginComfortComparison')}
          >
            <ParagraphTitle weight="bold">{t('plugin.comfort')}</ParagraphTitle>
            <ParagraphText>{t('plugin.screen')}</ParagraphText>
          </Block>
          <Block
            titleText={t('plugin.access')}
            imageFluid={images[language].tooltip.fluid}
            isImageLeft={true}
            alt={t('seo.alt.plugin.pluginEasyAccess')}
          >
            <ParagraphTitle weight="bold">{t('plugin.size')}</ParagraphTitle>
            <ParagraphText>{t('plugin.form')}</ParagraphText>
            <ParagraphTitle weight="bold">{t('plugin.comparison')}</ParagraphTitle>
            <ParagraphText>{t('plugin.customers')}</ParagraphText>
          </Block>
          <SwitcherBlock
            mainTitle={t('plugin.shoes-bra')}
            titleOne={t('plugin.shoe')}
            textOne={t('plugin.shoe-reco')}
            titleTwo={t('plugin.underwear')}
            textTwo={t('plugin.underwear-reco')}
            imageFluidOne={images[language].shoes.fluid}
            imageFluidTwo={images[language].bra.fluid}
            altOne={t('seo.alt.plugin.pluginShoes')}
            altTwo={t('seo.alt.plugin.pluginUnderwear')}
          />
        </Container>
      </section>
      <Background style={{ display: 'inline-block' }}>
        <section style={{ margin: '0 auto' }}>
          <ClientCase />
        </section>
      </Background>
      <Links withBlockTitle={true} links={['dashboard']} />
    </Layout>
  );
};

export default PluginPage;
